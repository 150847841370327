.landing-page-container {
    height: 100vh;
    background: linear-gradient(135deg, #1f4037, #99f2c8);
    color: white;
    overflow: hidden;
  }
  
  .landing-logo {
    max-width: 70%;
    max-height: 70%;
    transition: transform 0.5s ease;
  }
  
  .animate-logo {
    animation: zoomIn 1s ease-in-out;
  }
  
  @keyframes zoomIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes colorChange {
    0% {
      color: #87CEFA; /* Light Blue */
    }
    25% {
      color: #FFD700; /* Gold */
    }
    50% {
      color: #fed8eb; /* Hot Pink */
    }
    75% {
      color: #dbfcdb; /* Lime Green */
    }
    100% {
      color: #fec664; /* Orange Red */
    }
  }
  .landing-content {
    color: white;
  }
  
  .highlight {
    animation: colorChange 10s infinite alternate;
  }
  
  .pulse-button {
    background-color: #008CBA; /* Blue color for main CTA */
    color: white;
    font-weight: bold;
    border-radius: 15px;
    padding: 0.75rem 2rem;
    animation: pulse 2s infinite;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    border: none;
  }
  
  .pulse-button:hover {
    background-color: #00BFFF; /* Lighter blue on hover */
    transform: scale(1.1); /* Slightly larger on hover */
    transition: transform 0.3s ease-in-out;
  }
  .action-buttons .btn-join-now {
    background-color: #FF4500; /* Orange-red color for attention */
    color: white;
    font-weight: bold;
    border-radius: 15px;
    padding: 0.75rem 2rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border: none;
  }
  
  .action-buttons .btn-join-now:hover {
    background-color: #FF6347; /* Lighter orange-red on hover */
    transform: scale(1.1); /* Slightly larger on hover */
    transition: transform 0.3s ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .action-buttons .btn-lg {
    margin-right: 15px;
  }
  
  