.App {
  text-align: center;
}
body {
  height: 100%;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* General Navbar Styling */
.custom-navbar {
  background: linear-gradient(135deg,#1f4037, #99f2c8); /* Light-to-dark gradient */
  color: white;
  padding: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Shadow for separation from page */
}

/* Navbar Brand with Color Changing Effect */
.navbar-brand-custom {
  font-size: 1.5rem;
  font-weight: bold;
  color: #e0ffdb; /* Black color for contrast */
}

/* Nav Link Styling */
.nav-link-custom {
  color: white;
  transition: color 0.3s ease-in-out;
}

.nav-link-custom:hover {
  color: #979797;
}

/* Button Styling */
.nav-buttons .btn {
  margin-left: 0.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Sign In / Sign Up Buttons */
.nav-buttons .btn-signup {
  background-color: #FF4500; /* Bright Orange to stand out */
  color: white; /* White text */
  font-weight: bold;
  border-radius: 15px; /* Rounded button for a modern look */
  padding: 0.5rem 1.5rem; /* Increase padding for a larger button */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  border: none; /* Remove border */
}

.nav-buttons .btn-signup:hover {
  background-color: #FF6347; /* Slightly lighter on hover */
  color: rgb(15, 4, 4);
}

.nav-buttons .btn-signin {
  background-color: #008CBA; /* Bright Blue for contrast */
  color: white;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border: none;
}

.nav-buttons .btn-signin:hover {
  background-color: #00BFFF; /* Lighter blue on hover */
  color: white;
}

/* Responsive Fix for Buttons */
@media (max-width: 992px) {
  .d-lg-none {
    display: block !important; /* Display mobile button */
  }

  .d-lg-block {
    display: none !important; /* Hide desktop button */
  }
}
.nav-buttons .btn-profile {
  background-color: #4CAF50; /* Green for profile */
  color: white;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Shadow for separation */
  border: none; /* No border */
}

.nav-buttons .btn-profile:hover {
  background-color: #66BB6A; /* Lighter green on hover */
  color: white;
}

.nav-buttons .btn-signout {
  background-color: #DC143C; /* Crimson red for Sign Out */
  color: white;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border: none;
}
.nav-buttons .btn-create {
  background-color: #6A5ACD; /* Vibrant Purple */
  color: white;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  border: none;
}
.btn-create {
  background-color: #6A5ACD; /* Vibrant Purple */
  color: white;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  border: none;
}
.nav-buttons .btn-create:hover {
  background-color: #9370DB; /* Slightly lighter purple on hover */
  color: white;
}
.nav-buttons .btn-signout:hover {
  background-color: #FF6347; /* Lighter red on hover */
  color: white;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Card {
  border-radius: 10px;
  transition: transform 0.3s ease;
}
.Card:hover {
  transform: scale(1.05);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
